import { 
  amandalorian_screenshot, 
  rock_screenshot, 
  tictaccatjam_screenshot, 
  rockcollector_screenshot, 
  lamdb_screenshot,
  calculator_screenshot,
  yelpcamp_screenshot,
} from "../assets/images/exports"


const projects = [
  {
    title: "Rock Collector",
    description: "Collect rocks, feed them lichen, moss and mushrooms, and give them pet frogs to keep them company.",
    image: rockcollector_screenshot,
    repositoryLink: "https://github.com/shakestuffup73/rock-collector",
    deploymentLink: "https://rock-collector.herokuapp.com/about/",
    languages: "HTML5, CSS3, Python",
    details: "Full-Featured Framework Application Built Utilizing Django and Docker",
    tech: "Django, Docker, Python, HTML5, CSS3, JWT Auth"
  },
  {
    title: "Yelp Camp",
    description: "An application for finding and reviewing campgrounds.",
    image: yelpcamp_screenshot,
    repositoryLink: "https://github.com/shakestuffup73/yelpcamp",
    deploymentLink: "https://yelpcampgrounds.herokuapp.com/",
    languages: "EJS, CSS3, Javascript",
    details: "MEN Stack CRUD Application: MongoDB, Express, NodeJS, Javascript, HTML5, CSS3, Passport Auth",
    tech: "MEN Stack CRUD Application: MongoDB, Express, NodeJS, Javascript, HTML5, CSS3, Passport Auth",
  },
  {
    title: "Rock and Ice",
    description: "A community forum for rock and ice climbers to share climbs, comment on climbs, share their skills, and add climbing partners to their profiles.",
    image: rock_screenshot,
    repositoryLink: "https://github.com/shakestuffup73/appalachian-mtns",
    deploymentLink: "https://appalachian-mtns.fly.dev/",
    languages: "EJS, CSS, Javascript",
    details: "MEN Stack CRUD Application: MongoDB, Express, NodeJS, Javascript, HTML5, CSS3",
    tech: "MEN Stack CRUD Application: MongoDB, Express, NodeJS, Javascript, HTML5, CSS3, GoogleOAuth"   
  },
  {
    title: "LAMdb",
    description: "A secure database for managing pets' veterinary records.",
    image: lamdb_screenshot,
    repositoryLink: "https://github.com/shakestuffup73/lamdb-frontend",
    deploymentLink: "https://lamdb.netlify.app/",
    languages: "Front-end: Javascript, CSS, HTML, Back-end: Javascript",
    details: "GitHub Collaborative Group Fullstack Project",
    tech: "MERN Stack CRUD Application: MongoDB, Express, React, NodeJS, Javascript, HTML5, CSS3",
  },
  {
    title: "React Calculator",
    description: "A Simple React Calculator SPA application",
    image: calculator_screenshot,
    repositoryLink: "https://github.com/shakestuffup73/calculator",
    deploymentLink: "https://legendary-halva-72ebda.netlify.app/",
    languages: "Javascript, CSS3",
    details: "Single page application managing state in React",
    tech: "React App: Javascript, CSS3"
  },
  {
    title: "Amandalorian",
    description: "A choose-your-own adventure game inspired by the Disney Series The Manadalorian.",
    image: amandalorian_screenshot,
    repositoryLink: "https://github.com/shakestuffup73/amandalorian",
    deploymentLink: "https://amandalorian.netlify.app/",
    languages: "HTML, CSS, Javascript",
    details: "",
    tech: "Javascript, HTML5, CSS3"  
  },
  {
    title: "Tic-Tac-CatJam",
    description: "First software development project from GA's Software Engineering Immersive Bootcamp - an interactive tic-tac-toe game with music and flashing light effects for the win!",
    image: tictaccatjam_screenshot,
    repositoryLink: "https://github.com/shakestuffup73/ttt-weekend",
    deploymentLink: "https://tictaccatjam.fly.dev/",
    languages: "HTML, CSS, Javascript",
    details: "Browser-based game",
    tech: "Javascript, HTML5, CSS3"  
  },
]

export default projects