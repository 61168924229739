import styles from './Intro.module.css'
import Lottie from 'react-lottie-player'
import arrow from '../../assets/lotties/arrow.json'
import 'animate.css'
import '../../../src/darkMode.css'
import { useEffect, useState } from 'react'
import { DarkModeSwitch } from 'react-toggle-dark-mode'

const Intro = () => {

  const [theme, setTheme] = useState('dark')
  const [isDarkMode, setDarkMode] = useState(true)

  const toggleDarkMode = () => {
    if (theme === 'dark') {
      setTheme('light')
      setDarkMode(false)
    } else {
      setTheme('dark')
      setDarkMode(true)
    }
  }

  useEffect(() => {
    document.body.className = theme
  }, [theme])

  return ( 
    <div className={styles.introDiv}>
      <div className={styles.name}>
        <h1 className="animate__animated animate__fadeInDown">AMANDA STERN</h1>
        <h3 className="animate__animated animate__fadeInDown">Project Manager (PMP) || Software Developer || Photographer || Loyal Human To My Dogs</h3>
        <h2>Explore My Portfolio</h2>
        <Lottie
          loop
          animationData={arrow}
          play
          style={{ width: 40 }}
        />
      <DarkModeSwitch 
        onChange={toggleDarkMode} 
        checked={theme && isDarkMode} 
        style={{ color: 'whitesmoke'}}>Light/Dark
      </DarkModeSwitch>
      </div>
    </div>
  );
}
export default Intro
