import { ga_logo, AT_logo, DOI_logo, OSU_logo, CSN_logo, OB_logo, NOLS_logo, AF_logo, Cornell_logo, Cabem, Nephria, WCLLC_icon } from "../assets/images/exports"

const experiences = [
  {
    title: "Welsford Consulting LLC",
    role: "Project Management Consultant, Technical Project Manager",
    description: "Remote Technical Project Manager & Marketing/Branding Consultant",
    details: "Private consulting firm for product development, federal regulatory affairs, business operations, marketing/branding, technical project management, business finance and strategic business development",
    info: "Consultant for a private firm, responsibilities description found on resume",
    image: WCLLC_icon,
    date: "June 2024 - Present",
    altText: "Technical Project Manager & Marketing/Branding Consultant"
  },
  {
    title: "Nephria Bio",
    role: "Technical Project Manager & Software Developer",
    description: "Full-time remote Technical Project Manager",
    details: "Work on proprietary kidney dialysis medical device product branding & design for innovative technologies/medical solutions, manage UX/UI design and front-end development teams for U.S. & international website development, conduct market research, product testing, product sales, interface with customers and clients",
    info: "Proprietary medical device start-up tech project, responsibilities description found on resume",
    image: Nephria,
    date: "November 2023 - June 2024",
    altText: "Technical Project Manager & Software Developer", 
  },
  {
    title: "Cabem Technologies",
    role: "Software Developer",
    description: "Full-time remote full-stack (LAMP stack) Software Developer",
    details: "Work on project utilizing React, React-Redux, Javascript, HTML, CSS, Linux, Apache, DBeaver, MariaDB, PHP, SQL, Git, GitLab, Jira Tickets, interface with customer, move tickets through all phases of SDLC via Agile methodologies/development sprints",
    info: "Proprietary financial tech project, tech stack & responsibilities description found on resume",
    image: Cabem,
    date: "April 2023 - November 2023",
    altText: "Cabem Developer", 
  },
  {
    title: "General Assembly",
    role: "Software Engineering Fellow",
    description: "3-month full-time Software Engineering Immersive Bootcamp",
    details: "Developed projects utilizing Javascript, HTML, CSS, Python, Express, React, Django, Mongoose, MongoDB, PostgreSQL, SQL, and Docker",
    info: "Projects and descriptions found on Portfolio and GitHub",
    image: ga_logo,
    date: "August 2022 - November 2022",
    altText: "General Assembly Software Engineering resume experience", 
  },
  {
    title: "Sabbatical",
    role: "Appalachian Trail",
    description: "Backpacked for 600 continuous miles over 4 mos. on the AT from Georgia to Virginia",
    image: AT_logo,
    date: "March 2022 - June 2022",
    altText: "Appalachian Trail experience" 
  },
  {
    title: "Bureau of Land Management - Billings, Montana",
    role: "Park Ranger",
    description: "Performed field work in wilderness areas for recreation & wildland fire programs, educated public",
    details: "Assessed/mitigated risk & dispatched 12+ drivers & 15+ vehicles in wildland firefighting",
    details2: "Maintained fleet vehicles, Wilderness Study Areas & dispersed recreation sites",
    info: "Utilized GIS Software and SQL database to plot data & create maps using ArcGIS Pro",
    image: DOI_logo,
    date: "April 2021 - November 2021",
    altText: "BLM Park Ranger resume experience", 
  },
  {
    title: "Student",
    role: "Oregon State University",
    description: "Enrolled full-time in Master's Level Geographic Information Science courses",
    details: "Courses Taken: Remote Sensing, Geographic Information Science, Geography, The World's Water",
    details2: "Credits applied toward Geographic Information Science Certificate",
    info: "Learned GIS Software, ArcPro and ArcMap to analyze biological and bathymetry data",
    image: OSU_logo,
    date: "November 2020 - April 2021",
    altText: "Oregon State University semester", 
  },
  {
    title: "Bureau of Land Management - Denver, Colorado",
    role: "Park Ranger",
    description: "Utilized Trimble GPS & GIS Software to plot data & create maps using ArcGIS",
    details: "Educated public on Leave No Trace ethics, general information, navigation",
    details2: "Assessed/mitigated risk & dispatched 20+ personnel, 25+ vehicles & supplies in wildland firefighting",
    info: "Coordinated efforts between Logistics, Supply & Ground Support on Incident Command Post (ICP)",
    image: DOI_logo,
    date: "April 2020 - November 2020",
    altText: "BLM Park Ranger resume experience", 
  },
  {
    title: "Student",
    role: "College of Southern Nevada",
    description: "Enrolled full-time in Las Vegas Photography Courses",
    details: "Courses Taken: Photography, Graphic Design",
    info: "Managed small photography business serving local Las Vegas clientele for portfolio building",
    image: CSN_logo,
    date: "Dec 2019 - April 2020",
    altText: "College of Southern Nevada resume experience", 
  },
  {
    title: "Outward Bound - Hurricane Island, Maine",
    role: "Logistics Manager",
    description: "Managed equipment for Maine wilderness backpacking and canoe programs",
    details: "Educated students on Leave No Trace ethics, wilderness skills, land navigation",
    details2: "Outfitted students with appropriate gear",
    info: "Educated students on interpersonal skills, expedition behavior, conflict resolution and team dynamics",
    image: OB_logo,
    date: "May 2019 - October 2019",
    altText: "Outward Bound resume experience", 
  },
  {
    title: "National Outdoor Leadership School",
    role: "Wilderness Medicine Rescue Semester",
    description: "90-day Team Expedition focused on Wilderness Emergency Medicine",
    details: "Backpacked canyons in Utah, rock climbed and backcountry skied in Wyoming",
    details2: "National Registry of EMT Licensed (NREMT)",
    info: "Leave No Trace Master Educator, Avalanche Rescue 1 & High Angle Rock Rescue Certified",
    image: NOLS_logo,
    date: "October 2018 - December 2018",
    altText: "NOLS Expedition resume experience", 
  },
  {
    title: "United States Air Force - Active Duty",
    role: "Acquisitions Officer - Captain",
    description: "Managed integrated product team in planning & execution of formal qualification testing for satellite software command/control program",
    details: "Facilitated communication as Government liaison to Lockheed Martin, Air Force Operational Test & Evaluation Center & Sandia National Laboratories",
    details2: "Briefed senior executives on development status, risk/opportunity management, program management reviews and design reviews, reviewed contract proposals, supported team contract negotiations & awards, oversaw contract modifications",
    info: "Collaborated with stakeholders for schedule development & test asset/resource sharing",
    image: AF_logo,
    date: "March 2016 - September 2018",
    altText: "Air Force resume experience", 
  },
  {
    title: "United States Air Force - Active Duty",
    role: "Acquisitions Officer - Lieutenant",
    description: "Managed 8-person engineering team to review technical specifications & requirements for military compliance with Federal Aviation Administration standards",
    details: "Authored justification/analyses to award sole-source contracts for military-specific cockpit software & hardware applications",
    details2: "Managed engineering team to develop software for automating flight strip printing at Air Bases in Korea",
    info: "Reviewed contract proposals, conducted contract negotiations & contract awards, coordinated with legal, finance, contracting teams to ensure program compliance with Federal Acquisition Regulations",
    image: AF_logo,
    date: "September 2012 - March 2016",
    altText: "Air Force resume experience", 
  },
  {
    title: "Student",
    role: "Cornell University",
    description: "Bachelor of Science (B.S.) double major in Animal Science & Communications",
    details: "D1 Varsity Polo starting player - #1 East Coast Regional Champions, #2 National Finals",
    details2: "",
    info: "Air Force ROTC - Scabbard & Blade Military Honor Society",
    image: Cornell_logo,
    date: "August 2008 - May 2012",
    altText: "Cornell resume experience", 
  },
]

export default experiences